import React from 'react'
import Navbar from '../../components/Navbar'
import girisImg1 from "../../assets/pictures/giris-ornek-sema.png"
import girisImg2 from "../../assets/pictures/giris-sema-2.png"
import girisImg3 from "../../assets/pictures/giris-ornek-resim-3.png"
import Paragraph from './Paragraph'
import Quotation from './Quotation'
import Title from './Title'


const Introduction = () => {
    return (
        <div className=' bg-gray-200'>
            <Navbar />
            <div className='flex mt-20 gap-3 flex-col p-5'>
                <header className='flex justify-center items-center flex-col gap-2'>
                    <h3 className=' text-3xl font-bold'>GİRİŞ</h3>
                    <h2 className='text-xl font-semibold text-center'>En Lütufkâr, En Merhametli olan Tanrı'nın adıyla</h2>
                </header>
                <Paragraph
                    text={"Bu, Tanrı’nın insanlığa son mesajıdır. Tanrı’nın tüm peygamberleri bu dünyaya geldi ve tüm kutsal yazılar iletildi. Tanrı’nın peygamberleri tarafından iletilen tüm mesajların arındırılıp tek bir mesajda birleştirilmesinin ve bundan böyle Tanrı’nın kabul ettiği tek dinin “Teslimiyet” (3:19, 3:85) olduğunun duyurulmasının zamanı geldi. “Teslimiyet,” Tanrı’nın mutlak otoritesini tanıdığımız ve tüm güce sahip olanın YALNIZCA Tanrı olduğuna; O’ndan bağımsız başka hiçbir varlığın herhangi bir güce sahip olmadığına dair sarsılmaz bir kanaate ulaştığımız dindir. Böyle bir farkındalığın doğal sonucu, yaşamlarımızı ve tapınmamızı mutlak bir şekilde YALNIZCA Tanrı’ya adamaktır. Bu, Eski Ahit, Yeni Ahit ve bu Son Ahit de dâhil olmak üzere tüm kutsal yazılardaki İlk Buyruktur."}
                />
                <Quotation text={"Dinle, Ey İsrail! Tanrımız Rab Tek Tanrı’dır! \n Bu yüzden Tanrınız Rabbi çok sevin \n bütün yüreğinizle, \n bütün ruhunuzla,\nbütün aklınızla \nve bütün gücünüzle.\n\n[Yasa’nın Tekrarı 6:4-5, Luka 12:29-30, Kuran 3:18]"} />
                <Quotation text={"Tanrı’nın, O’nun şanlı niteliklerinin üzerinde derin derin düşünelim;\n evrenin Yaratıcısı olarak bu evrendeki her şeyin temeli olandır,\n Her Yerde Var Olan, Her Şeye Gücü Yeten, Her Şeyi Bilen olarak tapılmaya layık olandır\n ve var oluşu kendinden olan bilinçli varlıktır,\n tüm cehaleti ve kirleri akıldan kaldıran\n ve zihnimizi arındırıp keskinleştirendir.\n\n [Gayatri Mantra, Yajur Veda]"} />
                <Paragraph text={"Her din bidatler, gelenekler ve sahte, putperest doktrinler tarafından bozulmuş olsa da, her dinde “Teslim Olanlar” olabilir. Hristiyan, Yahudi, Müslüman, Hindu, Budist veya daha başka Teslim Olanlar olabilir. Bu Teslim Olanlar, toplu olarak Tanrı’nın kabul ettiği tek dini teşkil ederler. Bu kitabın ön sayfasında yer alan temada vurgulandığı gibi, YALNIZCA Tanrı’ya adanmış olan ve Tanrı’nın yanına hiçbir put yerleştirmeyen tüm Teslim Olanlar, Tanrı’nın ebedi krallığına tekrar kabul edilirler (2:62). Gerçek teslim olanların bir ölçütü, onların Kuran’da itiraz edilebilir hiçbir şey bulmayacak olmalarıdır."} />
                <Paragraph text={"Bu Ahit’in gelişiyle birlikte, Tanrı’nın dünyaya mesajı artık tamdır/eksiksizdir. Artık en kaçınılmaz sorularımıza uzun zamandır beklenen cevapları almış bulunuyoruz—biz kimiz, yaşamlarımızın amacı, bu dünyaya nasıl geldik, buradan nereye gidiyoruz, hangi din doğru, evrim mi yoksa yaratılış mı vb."} />
                <Title text={"Yaratılıştan Önce"} />
                <Paragraph text={"Bazıları merak edebilir: “Tanrı, mesajını tamamlamak ve birleştirip sağlamlaştırmak için neden bunca zaman bekledi? Peki ya Âdem’den bu yana kutsal yazıların tamamını almamış tüm insanlara ne olacak?” Kuran’ın bu soruyu 20:52’de cevapladığını akılda tutarak, dünya nüfusunun başından bugüne kadar 7.000.000.000’ı aşmadığı basit bir istatistiki meseledir. Şu andan itibaren Dünya’nın sonuna kadar, M.S. 2280 (Ek 25), toplam dünya nüfusunun 75.000.000.000’ı geçeceği tahmin edilmektedir. Dolayısıyla, insanların büyük birçoğunluğu Tanrı’nın arındırılmış ve birleştirilip sağlamlaştırılmış mesajını almaya mukadderdir (Şemaya bakınız)."} />
                <p className='bg-white p-5 flex flex-col items-center justify-center'><img src={girisImg1} alt='' />[Siyah alan, Âdem’den bu yana olan dünya nüfusunu temsil ediyor]</p>
                <Paragraph text={"Her şey milyarlarca yıl önce, Tanrı’nın yüksek rütbeli yaratıklarından biri olan Şeytan’ın, bir hâkimiyet alanını, Tanrı’nın yanında bağımsız bir tanrı olarak yönetebileceği konusunda kibirli bir fikir geliştirmesiyle başladı. Tanrı’nın mutlak otoritesine olan bu meydan okuma sadece bir küfür değildi, aynı zamanda hatalıydı da. Şeytan, yalnızca Tanrı’nın bir tanrı olma kabiliyetine sahip olduğu ve tanrılığın onun idrakinden çok daha fazlası olduğu gerçeği konusunda cahildi. Şeytan’ı, bir hâkimiyet alanının sorumluluğunu bir tanrı olarak üstlenebileceğine ve onu hastalık, sefalet, savaş, kazalar ve kaos olmadan yönetebileceğine inandıran şey egoydu—cehaletle artmış kibir. Tanrı’nın yaratıklarının ezici çoğunluğu Şeytan’la aynı fikirde değildi. Yine de çeşitli derecelerde onunla aynı fikirdeki sayıca çok az olan egoist azınlık, milyarlarca sayıda idi. Böylelikle, Göksel Topluluk içinde derin bir münakaşa patlak verdi (38:69). İsyancıların Tanrı’nın mutlak otoritesine karşı olan haksız meydan okuması en etkin şekilde karşılanıp çözüldü. İsyancılara suçlarını kınamaları ve O’na teslim olmaları için yeterince şans verildikten sonra, Tanrı en inatçı isyancıları Dünya adında bir uzay gemisine sürgün etmeye ve kendilerini günahın bedelinden kurtarmaları için onlara yine de başka bir şans vermeye karar verdi."} />
                <Paragraph text={"Eğer bir uçağı uçurabileceğinizi iddia ediyorsanız, iddianızı test etmenin en iyi yolu size bir uçak vermek ve onu uçurmanızı istemektir. Tanrı’nın, Şeytan’ın bir tanrı olabileceği iddiasına yanıt olarak yapmaya karar verdiği şey tam da buydu; Tanrı onu minik zerre olan Dünya’da geçici bir tanrı olarak atadı (2:30, 36:60). Şeytan’la aynı fikirde olanlara gelince, onlara egolarını öldürmeleri ve Tanrı’nın mutlak otoritesine teslim olmaları için bir şans verildi. Suçlu yaratıkların ezici çoğunluğu bu fırsattan yararlanırken, yaklaşık 150 milyar yaratıktan oluşan ufacık bir azınlık bu tekliften yararlanmakta başarısız oldu (33:72)."} />
                <p className='bg-white p-5 flex flex-col items-center justify-center'><img src={girisImg2} alt='' /></p>
                <Paragraph text={"Göksel Topluluk’taki münakaşa Tanrı’nın yaratıklarının farklı kategorilere sınıflandırılmasına yol açmıştır:"} />
                <Title text={"(1) Melekler"} />
                <Paragraph text={"Tanrı’nın mutlak otoritesini asla sorgulamayan yaratıklar melekler olarak sınıflandırıldı; yalnızca Tanrı’nın tanrı olma yeteneğine ve niteliklerine sahip olduğunu biliyorlardı. Tanrı’nın yaratıklarının ezici çoğunluğu—sayısız çoklukta—bu kategoriye aittir. Meleklerin sayısı o kadar muazzamdır ki, melekler bile kendilerinin kaç tane olduklarını bilmezler; onların sayısını ancak Tanrı bilir (74:31)."} />
                <Title text={"(2) Hayvanlar"} />
                <Paragraph text={"Melekler, isyancıların ve liderlerinin Tanrı’nın krallığından sürgün edilmeleri gerektiğini önermiş olmalarına rağmen (2:30), En Merhametli Olan, isyancılara suçlarını kınamaları, tövbe etmeleri ve O’nun mutlak otoritesine teslim olmaları için bir şans vermeyi irade etti (33:72). Yukarıdaki şemada gösterildiği gibi, asilerin büyük çoğunluğu Tanrı’nın krallığına yeniden katılma yönündeki lütufkâr tekliften faydalandı. Egolarını öldürüp, küfürlerinin bir kefareti olarak teslimiyetçi bir rolü yerine getirmek için bu dünyaya gelmeyi kabul ettiler. Bu dünyadaki teslimiyetçi rollerine karşılık olarak, bu yaratıklar, günahlarından kurtarılarak Tanrı’nın sonsuz krallığına geri alınırlar (6:38). At, köpek, ağaç, Güneş, Ay, yıldızlar, aynı zamanda deforme olmuş ve retarde çocuklar, suçlarını kınayan ve tövbe eden zeki yaratıklar arasındadır:"} />
                <Quotation text={"Göklerdeki ve yerdeki her şeyin; Güneş’in, Ay’ın, yıldızların, dağların, ağaçların, hayvanların, aynı zamanda birçok insanın Tanrı’ya secde ettiklerini görmez misin? Bununla birlikte, birçok insan ise azap için mukadderdir. [22:18]"} />
                <Quotation text={"Yıldızlar ve ağaçlar secde ederler. [55:6]"} />
                <Paragraph text={"Atın egosu yoktur. Atın sahibi zengin veya fakir, uzun veya kısa, şişman veya zayıf, genç veya yaşlı olabilir ve at hepsine hizmet edecektir. Köpeğin egosu yoktur; sahibi ne kadar zengin ya da fakir olursa olsun kuyruğunu sahibine sallayacaktır. Güneş her gün tam olarak Tanrı’nın tayin ettiği zamanlarda doğar ve batar. Ay, Dünya etrafındaki senkronize yörüngesini en ufak bir sapma olmadan takip eder. İnsan vücudu—geçici bir elbise—yeryüzüne aittir; bu bakımdan, o da bir teslim olandır. Kalp, akciğerler, böbrekler ve diğer organlar kontrolümüz dışında fonksiyonlarını yerine getirirler."} />
                <Title text={"(3) İnsanlar"} />
                <Paragraph text={"Sabit fikirli isyancılar—insanlar ve cinler—suçlarını kınamayı reddettiler ve Şeytan’ın iddiasının gösterisine şahitlik etmeyi seçtiler. Tanrı’nın mutlak otoritesine, bunu yapmaları için bir şans önerildiğinde bile teslim olmayı başaramayan bu egoist yaratıklar ikiye bölündüler. Şeytan’ın bakış açısına daha az ikna olmuş olan yarısı insanlar olarak sınıflandırıldı. Şeytan’ın iddiası hakkında şüphe barındırdıkları halde, Tanrı’nın mutlak otoritesi konusunda sağlam bir duruş sergilemeyi başaramadılar. Bu yaratıkların Tanrı’nın her şeye gücü yetmesini takdir etmelerine engel olan şey egodur, kendilerine böylesi bir fırsat sunulduğunda (33:72) teslim olmalarını engelleyen şey egodur ve çoğumuz ile günahın bedelinden Tanrı’nın krallığına kurtuluş arasında duran şey de egodur (25:43). Bu nedenle, “Egonu öldür!” Kuran’daki ilk buyruklardan biridir (2:54)."} />
                <Title text={"(4) Cinler"} />
                <Paragraph text={"Suçlu yaratıkların diğer yarısı, Şeytan’ın bakış açısına daha yakın olan ve en büyük egoları sergileyenler, cinler olarak sınıflandırıldı. Doğumdan ölüme kadar her insanoğluna bir cin atamak Tanrı’nın planıydı. Bu cin yoldaş Şeytan’ı temsil eder ve onun bakış açısına teşvik eder (50:23, 27). Hem cinlere hem de insanlara kendilerini yeniden eğitmeleri, egoistliklerini kınamaları ve Tanrı’nın mutlak otoritesine teslim olarak kendilerini günahın bedelinden kurtarmaları için bu dünyada değerli bir şans verilmektedir. Ne zaman bir insan doğsa, bir cin doğar ve bu yeni insana atanır. Kuran’dan cinlerin Şeytan’ın soyu olduğunu öğreniyoruz (7:27, 18:50). Bir cin doğup bir insana atandığı zaman, bu cin, o insan ölünceye kadar o insanın daimî bir yoldaşı olarak kalır. Bu cin daha sonra serbest bırakılır ve birkaç yüzyıl boyunca yaşar. Hem insanların hem de cinlerin yalnızca Tanrı’ya tapınmaları gerekmektedir (51:56)."} />
                <Title text={"Tanrı Robotlar İstemez"} />
                <Paragraph text={"38:69’da belirtilen ve yukarıda anlatılan Göksel Topluluk’taki münakaşa, Tanrı’nın yaratıklarının seçim özgürlüğüne sahip olduğunu kanıtlar; onlar kendi akıllarına sahiptirler. Tanrı’nın yaratıkları arasındaki ufacık bir azınlığın isyanı, Tanrı’nın yaratıklarının, O’nun sonsuz ihtişamını takdir ettikleri için O’na kulluk ettiklerine dair muhteşem gerçeği vurgulamaya hizmet etti. İsyan olmadan, özgürlüğün Tanrı’nın yaratıklarına armağanı olduğunu asla bilemezdik."}/>
                <Title text={"En Lütufkâr, En Merhametli"} />
                <Paragraph text={"Dünyevi boyutumuzda bile, herhangi bir işletme, çalışanlarının sadık ve kendilerini işletmenin refahına adamış olmalarını bekler Eğer bir çalışan, işletmeye bütünüyle adanmamışsa veya bölünmüş sadakat göstermişse, derhal işten çıkarılır. İnsanlar ve cinler Şeytan’ın tarafını tuttukları, ardından da isyankâr eylemlerini yeniden gözden geçirme teklifini geri çevirdikleri için, melekler Şeytan’ın ve müttefiklerinin Tanrı’nın krallığından kovulmasını beklediler (2:30). Suçumuzu kınamamız ve kendimizi günahın bedelinden kurtarmamız için bize bu ek şansı bahşetmesi, Tanrı’dan büyük bir rahmetti."}/>
                <Paragraph text={"Bu son derece merhametli olan günahın bedelinden kurtuluş planını gerçekleştirmek için Tanrı “ölümü yarattı” (67:1-2). İlahi plan, isyancıları göksel kavga ile alakalı hiçbir belleklerinin olmadığı başka bir varoluşa getirmeyi gerektiriyordu. Bu hayatın koşulları altında, insanlar ve cinler hem Tanrı’nın mesajlarını alırlar, hem de Şeytan’ın mesajlarını, sonra her iki taraftan birini özgürce seçerler. Özgür iradeleri ile aldıkları karara dayanarak, ya günahın bedelinden Tanrı’nın krallığına kurtulurlar yahut Şeytan ile birlikte kalıcı olarak sürgün edilirler."}/>
                <Title text={"Şeytan’ın Geçici Hâkimiyeti"} />           
                <Paragraph text={"Şeytan’ın öngörülen hâkimiyetinin mutlak önemsizliğini vurgulamak için, Tanrı, milyarlarca ışık yılı genişlikteki uçsuz bucaksız bir evrende bir milyar galaksi, bir milyar trilyon yıldız yarattı. Eğer ışık hızında Güneş’e doğru (93.000.000 mil) seyahat edersek, ona sekiz dakikada ulaşırız. Eğer gitmeye devam edersek, Samanyolu Galaksimizin sınırına ışık hızında 50-70.000 yıl sonra ulaşırız. En yakın galaksiye ulaşmak, ışık hızında 2.000.000 yılımızı alacaktır ve “bizim evrenimizde” en az 2.000.000.000 galaksi vardır. En güçlü teleskoplarla Dünya, evrenimizin sınırı şöyle dursun bizim kendi galaksimizin sınırından tümüyle görünmezdir. Bizim evrenimiz yeterince geniş değilmişçesine, Tanrı evrenimizi çevreleyen altı tane daha yarattı, üstelik daha geniş evrenler (2:29, 67:3). Tanrı daha sonra Şeytan’a, en küçük ve en içteki evrende bulunan ufacık bir zerrenin, Dünya gezegeninin onun hâkimiyet alanı olduğunu bildirdi. Tanrı’nın planı, insanları ve cinleri Kendi fiziksel varlığına dayanamayan bir evrene yerleştirmeyi icap ettirdi (7:143). Böylelikle Şeytan, Tanrı’nın tüm bilgisi ve kontrolü ile birlikte, minik krallığını Tanrı’nın fiziksel varlığından uzakta idare etmektedir. Ayrıca dikkat edilmelidir ki, tövbe eden isyancıların sayısı oldukça fazlaydı, öyle ki Dünya gezegeninin onların hepsine kalacak yer sağlaması mümkün değildi. Bu gezegen üzerinde hayvanların insanlardan oldukça fazla olması bundandır. Tövbe etmiş tüm asilere ev sahipliği yapmak, yönetilemez bir yeryüzü meydana getirecekti. Yaratıkların sayısız desilyonlarının uzay boşluğunda yerleştirilmesi bu nedenledir."}/>
                <Title text={"Âdem ve Havva"} />  
                <Paragraph text={"İlk insanın bedeni, Tanrı’nın melekleri tarafından, Tanrı’nın talimatlarına uygun olarak yeryüzünde şekillendirildi (7:11). Tanrı daha sonra ilk kişiyi, yani Âdem’i bu bedene atadı. Tanrı meleklere, test süresi boyunca insanlara hizmet edeceklerini bildirdiğinde—onları korumak, rüzgârları sürmek, yağmuru ve rızıkları dağıtmak vb.—”secdeye kapanmayı” reddeden tek kişi Şeytan’dı (2:34, 15:31, 38:74). Âdem’in eşi, dişil özelliklerle Âdem’den klonlandı ve Tanrı ikinci insanı onun bedenine atadı. Âdem ve Havva’nın boş (ruhsuz) bedenleri burada, yeryüzünde kalırken, ruhları, yani gerçek kişiler Cennette ikamet etti. Âdem ve Havva, Tanrı’nın buyruklarına sarıldıkları sürece Cennette kaldılar. Bunun yerine Şeytan’ı dinledikleri an, hepimizdeki kusurlu insan doğasını yansıttılar ve hemen Şeytan’ın yeryüzündeki hâkimiyetine ait oldular—“bedenleri kendilerine görünür hale geldi” (7:20, 20:121). Gerisi tarihtir."}/>
                <Title text={"Şeytan: Tüm Cinlerin Babası"} /> 
                <Paragraph text={" Cinleri ve insanları teste sokmak, ne zaman bir insan doğsa Şeytan’ın çoğalmasını şart koşuyordu. Yukarıda bahsedildiği gibi, bir insan doğduğunda, bu insan kişinin sabit bir yoldaşı olarak görev yapması için bir de cin doğar. Her insan, doğumdan ölüme kadar aynı bedende yaşayan Şeytan’ın temsilcisinin aralıksız iknalarına maruz kalır. Şeytan’ın temsilcisi, insan yoldaşını Şeytan’ın bakış açısına ikna etmeye çalışır: Tanrı tek başına yeterli değildir. Yargı Günü’nde, cin yoldaşı insan mevkidaşına karşı bir şahit görevi görür (43:38; 50:23,27). Pek çok cin yoldaşı da insan yoldaşları tarafından Tanrı’nın bakış açısına döndürülür."}/>
                <Paragraph text={"Tanrı, insanoğlunu hazırlıksız bırakmadı. İnsanlara küfürlerini yeniden gözden geçirmeleri için verilen son şanslarında yardımcı olmak amacıyla, her insan, başka hiç kimsenin değil YALNIZCA Tanrı’nın bizim Sahibimiz ve Efendimiz olduğuna yönelik içgüdüsel bir bilgi ile doğar. (7:172-173). Cinlere bu içgüdüsel bilgi verilmemiştir; fakat onlara en içteki evren boyunca yer alan Tanrı’nın işaretlerini incelemeleri için çok daha fazla yaşam süresi ve daha büyük yetenekler verilmiştir. Şeytan’ın bakış açısını temsil ettikleri için, onların içgüdüsel doğaları güçlü bir şekilde çoktanrıcılığa meyillidir. Yalnızca Tanrı’ya tapmaya yönelik dâhili içgüdümüze ek olarak, Tanrı kendimizi günahın bedelinden kurtarmamıza yardımcı olmak için elçiler gönderir. Tüm bu unsurları göz önüne aldığımızda, (eğer ölünceye kadar devam ettirilirse) bağışlanamaz tek suçun puta tapmak olduğu gerçeğini takdir edebiliyoruz: Tanrı’nın yanında herhangi birinin herhangi bir güce sahip olduğuna inanmak."}/>
                <Title text={"Kırk Yıllık Lütuf Dönemi"} /> 
                <Paragraph text={" İnsanoğluna, bu en önemli kararı—Şeytan’ın bakış açısına yahut Tanrı’nın mutlak otoritesine sarılma kararını—vermeden önce araştırması, etrafa bakması, derin derin düşünmesi ve tüm bakış açılarını incelemesi için kırk yıl verilir. Kırk yaşından önce ölen herkes, sadece Tanrı’nın bildiği koşullardan ötürü Tanrı tarafından günahın bedelinden kurtuluş için seçilmiştir. 40 yaşından önce ölen herkes Cennete gider (46:15, Ek 32). Tanrı’nın muazzam merhameti, Kuran’a iman edenlerin bile böyle şefkatli bir ilahi kanunu kabul etmekte zorlanmaları gerçeğinden bellidir."}/>
                <Paragraph text={" Tanrı’nın elçileri, kendimizi günahın bedelinden kurtarmamız için Tanrı’nın verdiği şansın müjdesini ilettiler ve müthiş işaretler ile desteklendiler. Musa Firavun’a gittiğinde, değneğinin bir yılana dönüşmesi gibi mucizelerle desteklendi. İsa, Tanrı’nın izni ile çamurdan canlı kuşlar yarattı, Tanrı’nın izni ile cüzzamlıyı ve körü iyileştirdi ve Tanrı’nın izni ile ölüleri diriltti. Muhammed peygamber, bu Son Ahit’i iletmiş olan Tanrı’nın elçisi, bu tür mucizeler sergilemedi (10:20). Kuran’ın kendisi, Muhammed’in misyonunu destekleyen mucizeydi (29:50-51). Kuran’ın Mucizesini 14 yüzyıl boyunca Muhammed’den ayırmak ilahi bilgelikti. Artık Kuran’ın matematiksel mucizesinin büyük sonuçlar doğurabilecek boyutlarını kavradığımıza göre (Ek 1) şunu anlıyoruz ki, eğer bu Mucize onun aracılığıyla vahyedilseydi, milyonlarca insan Muhammed’e Tanrı’nın ete kemiğe bürünmüş hali olarak tapınırdı."}/>
                <Title text={"Gerçeklik Kanıtı:\nFiziksel, Somut, Çürütülemez."} /> 
                <Paragraph text={" Bilgisayar çağının gelişiyle birlikte, Kuran’ın matematiksel kodunun, 74:30-35’te belirtildiği gibi “En büyük mucizelerden biri” olduğunu keşfettik. Önceki elçilere verilen mucizeler zaman ve mekânla sınırlı kalırken, Kuran’ın mucizesi daimidir. Sadece birkaç insan Musa’nın ve İsa’nın mucizelerine şahit oldu, fakat Kuran’ın mucizesine herhangi bir zamanda herhangi bir kişi tarafından şahit olunabilir. Dahası, Kuran’ın mucizesi önceki tüm mucizeleri belgelemekte ve kanıtlamaktadır (5:48)."}/>
                <Paragraph text={" Ek 1’de detaylıca anlatıldığı gibi, Kuran’ın matematiksel mucizesi “19” sayısına dayanmaktadır. Bu müthiş mucizeyi okuyucu ile paylaşmak için, İngilizce metin boyunca “TANRI” kelimesi koyu renkli büyük harfle yazılmıştır ve geçişlerin eklenerek artan sayısı her sayfanın sol alt köşesinde gösterilmektedir. Kuran’ın sonunda bu en önemli kelimenin toplam geçişi 2698 olarak gösterilmektedir. Bu, 19’un katıdır. İlaveten, “Tanrı” kelimesinin geçtiği her ayete atanan sayıları topladığımızda, toplam 118123 eder, bu da 19’un katıdır (19x6217). “Tanrı” kelimesinin geçtiği ayet numaralarının eklenerek artan toplamı her sayfanın sağ alt köşesinde gösterilmektedir. Bu basit fiziksel gerçekler, okuyucu tarafından kolaylıkla teyit edilebilir ve Kuran’ın matematiksel yapısının insan-üstü doğasını kanıtlamak için yeterlidir."}/>
                <Paragraph text={"“Tanrı” Kelimesinin Toplam Sayısı (Her sayfanın sol alt köşesinde gösterilmektedir) 2698 (19x142)"}/>
                <Paragraph text={"Ayet Numaralarının Toplamı (Sağ alt köşede gösterilmektedir) 118122 (19x6217)"}/>
                <Title text={"Okuyucu Tarafından Teyit Edilebilir Gerçeklik Kanıtı"} /> 
                <Paragraph text={" Kuran’ın olağanüstü matematiksel yapısına ek olarak, modern bilim tarafından kanıtlanmış yahut kuram haline getirilmiş çok sayıda Kuranî hakikat buluyoruz. İşte böyle ileri bilimsel bilginin birkaç örneği:"}/>
                <Quotation text={"1. Yeryüzü yumurta şeklindedir (39:5, 79:30).\n 2. Yeryüzü sabit durmaz; sürekli hareket eder (27:88).\n 3. Güneş bir ışık kaynağıdır, Ay ise onu yansıtır (10:5, 25:61, 71:16).\n 4. Gökyüzüne tırmandıkça oksijen oranı azalır (6:125).\n 5. “Big Bang Teorisi” doğrulandı (21:30).\n 6. “Evrenin Genişlemesi Teorisi” doğrulandı (51:47).\n 7. Evren bir gaz kütlesi olarak başladı (41:11).\n 8. Evrim bir gerçektir; belirli bir tür içinde, evrim ilahi olarak yönlendirilen bir süreçtir (21:30, 24:45, 32:7-9, 18:37, 15:28-29, 7:11, 71:13-14, Ek 31).\n 9. Bebeğin cinsiyetini erkeğin seminal sıvısı belirler (53:45-46)."} />
                <Title text={"Saçmalık Yok"} /> 
                <Paragraph text={" Kuran’da hiçbir saçmalığın olmaması, aynı derecede mucizevidir. Kuran’ın vahyedilişi sırasındaki cehalet ve batıl inançların baskınlığı göz önüne alınırsa, bu bilhassa önemlidir. Mesela, geleneksel Müslümanlar arasında en saygın tefsir İbn-i Kesir’inkidir. Peygamberden yüzyıllar sonra yazılmış bu ünlü referansta, yeryüzünün dev bir balinanın tepesinde duran 40.000 boynuzlu dev bir boğa üzerinde taşındığını okuyoruz (İbn Kesir’in 68:1 Ayetinin tefsirine bakınız)."}/>
                <Paragraph text={" Daha 1975 yılında ve Kuran’ın vahyedildiği aynı yerde, Suudi Arabistan Medine İslam Üniversitesi Rektörü Şeyh Abdul Aziz Bin Baz, yeryüzünün düz olduğunu ve sabit bir şekilde durduğunu deklare etti (Bkz. Ek)!!"}/>
                <p className='bg-white p-5 flex flex-col items-center justify-center'><img src={girisImg3} alt='' />[Bin Baz’ın kitabından çeviri, sayfa 23: “Dünya iddia ettikleri gibi dönüyorsa, ülkelerin, dağların, ağaçların, nehirlerin ve okyanusların hiçbir temeli olmayacaktır ve insanlar doğu ülkelerinin batıya, batı ülkelerinin doğuya hareket ettiğini görecektir.]</p>
                <Title text={"Mükemmel Mutluluk: Şimdi ve Sonsuza Dek"} /> 
                <Paragraph text={"Her insanın yakalanması en zor hedeflerinden biri “Mutluluk” tur. Kuran, bu hayatta ve sonsuza dek mükemmel mutluluğa ulaşmanın sırrını ortaya çıkarmaktadır. Kuran’dan, mutluluğun ruhun özel bir niteliği olduğunu öğreniyoruz. Nitekim arzuladığı tüm maddi başarılara—para, güç, şöhret vb.—ulaşan bir beden genellikle mutsuz bir kişiye aittir. Mutluluk tamamen ruhun, gerçek kişinin ulaştığı büyüme ve gelişme derecesine bağlıdır. Kuran, beden ve ruh için hem bu dünyada hem de ebedi Ahirette mükemmel mutluluğa yönelik ayrıntılı bir harita sunmaktadır (Ek 15)."}/>
                <Paragraph text={" Bu kanıtlanmış Ahit boyunca çok sayıda ayette, Tanrı, imanlıların şimdi ve sonsuza dek mutluluğunu şahsen garanti etmektedir:"}/>
                <Quotation text={"Kesinlikle Tanrı’nın müttefiklerinin korkacakları hiçbir şey yoktur,\n onlar üzülmeyecekler de.\n Ki onlar iman eden\n ve doğru bir hayat sürenlerdir.\n Onlar için mutluluk hem bu hayatta hem de Ahirettedir.\n Bu, Tanrı’nın değiştirilemez yasasıdır.\n Gerçek zafer işte budur.\n [10:62-64]"}/>
                <Title text={"Tüm İmanlılar, Kabul Edilen Tek Dini Oluştururlar"} /> 
                <Paragraph text={"Yaratıcının son mesajından beklendiği gibi, Kuran’da öne çıkan temalardan biri, tüm imanlılar arasında birlik çağrısı ve Tanrı’nın elçileri arasında herhangi bir ayrım yapmanın haram kılınmasıdır. Eğer tapınılan şey bir ve aynı ise, tüm imanlılar arasında mutlak bir birlik olacaktır. Yanlış yola iletilmiş imanlılar arasında bölünme, nefret ve acı savaşlara yol açan şey insan faktörüdür, yani İsa, Muhammed ve azizler gibi böylesi güçsüz insanlara karşı olan adanma ve tarafgirliktir. Doğru yola iletilmiş bir imanlı YALNIZCA Tanrı’ya adanmıştır ve YALNIZCA Tanrı’ya adanmış başka herhangi bir imanlıyı görmekten mutluluk duyar, böyle bir imanlının kendi dinine verdiği isim ne olursa olsun."}/>
                <Quotation text={"Şüphesiz, iman edenlerden,\n Yahudi olanlardan,\n Hristiyanlardan\n ve başka dine dönmüş olanlardan; her kim\n (1) Tanrı’ya iman eder,\n (2) Son Gün’e iman eder ve\n (3) doğru bir hayat sürerse,\n mükâfatlarını Rablerinden alacaklardır;\n onların korkacakları hiçbir şey yoktur, onlar üzülmeyecekler de.\n[ 2:62, 5:69 ]"} />
                
                <Title text={"Tanrı’nın Antlaşma Elçisi"} /> 
                <Paragraph text={"  Ek 2’de detaylandırıldığı gibi, bu kitabın yayımlanması yeni bir çağın gelişini işaret ediyor – Tüm peygamberleri tarafından iletilen Tanrı’nın mesajlarının birleştirilip sağlamlaştırıldığı çağ. Tanrı’nın bir ve tek dini, “Teslimiyet,” diğer tüm dinlere hâkim olacaktır (9:33, 48:28 ve 61:9). Yahudilik, Hristiyanlık, Hinduizm, Budizm ve İslam dâhil bugünün bozulmuş dinleri basitçe ortadan kaybolacak ve “Teslimiyet” galip gelecektir. Bu, bir insanoğlunun veya bir insan topluluğunun hüsnükuruntusu değildir; bu, Tanrı’nın dokunulmaz yasasıdır (3:19, 9:33, 41:53, 48:28, 61:9, 110:1)."}/>
                <p className=' text-right text-xl font-semibold' >Reşad Halife, Tuscon, Ramazan 26, 1409*</p>
                <Paragraph text={"*İlk baskının son taslağı Kader Gecesi 1409’da tamamlandı. Eğer bu tarihin gün, ay ve yılını toplarsak, 1444 veya 19x19x4 elde ederiz. [Ramazan 26, 1409: 9 + 26 + 1409 = 1444.]"}/>
                 
              
            </div>
        </div>
    )
}

export default Introduction